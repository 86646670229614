import React from 'react';
import './App.css';
import StopWatch from './Components/StopWatch/StopWatch';

function App() {
  return (
    <div className="App">
      <StopWatch />
    </div>
  );
}

export default App;
