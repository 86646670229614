import React, { useState, useEffect } from 'react';
import './StopWatch.css';
import tinykeys from 'tinykeys';
import Timer from '../Timer/Timer';

export default function StopWatch() {
  const [isActive, setIsActive] = useState(false);
  const [isPaused, setIsPaused] = useState(true);
  const [time, setTime] = useState(0);
  const [dateBeg, setDateBeg] = useState(0);

  const handlePauseReset = () => {
    if (time === 0) {
      setIsActive(true);
      setIsPaused(false);
      setDateBeg(Date.now());
    } else if (!isPaused) {
      setIsActive(false);
      setIsPaused(true);
    } else {
      setIsPaused(!isPaused);
      setTime(0);
      setDateBeg(0);
    }
  };

  useEffect(() => {
    let interval = null;
    const unsubscribe = tinykeys(window, {
      Space: () => {
        if (time === 0) {
          setIsActive(true);
          setIsPaused(false);
          setDateBeg(Date.now());
        } else if (!isPaused) {
          setIsActive(false);
          setIsPaused(true);
        } else {
          setIsPaused(!isPaused);
          setTime(0);
          setDateBeg(0);
        }
      },
    });
    if (isActive && isPaused === false) {
      interval = setInterval(() => {
        const diff = Date.now() - dateBeg;
        setTime(diff);
      }, 100);
    } else {
      clearInterval(interval);
    }
    return () => {
      clearInterval(interval);
      unsubscribe();
    };
  }, [isActive, isPaused, time, dateBeg]);

  return (
    <div
      className="stop-watch"
      onClick={handlePauseReset}
      onKeyPress={handlePauseReset}
      role="presentation"
    >
      <Timer
        time={time}
        active={isActive}
        isPaused={isPaused}
        dateBeg={dateBeg}
      />
    </div>
  );
}
