import React from 'react';
import './Timer.css';
import PropTypes from 'prop-types';

function Timer(props) {
  const { time } = props;
  return (
    <div className="timer">
      <span className="digits">
        {(`0${Math.floor((time / 60000) % 60)}`).slice(-2)}
        :
      </span>
      <span className="digits">
        {(`0${Math.floor((time / 1000) % 60)}`).slice(-2)}
        .
      </span>
      <span className="digits mili-sec">
        {Math.floor((time % 1000) / 100)}
      </span>
    </div>
  );
}

Timer.propTypes = {
  time: PropTypes.number.isRequired,
};

export default Timer;
